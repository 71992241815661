const React = require('react');
const ReactDOM = require('react-dom');
const R = require('ramda');

const { withRouter } = require('react-router');
const { bindActionCreators } = require('redux');
const { connect } = require('react-redux');

const classNames = require('classnames');

const RouteHelper = require('shared/routes/helper').default;

const KeypressEvents = require('../../../client/utils/keypress-events');
const WindowEvents = require('../../../client/utils/window-events');

const utils = require('../../../client/utils');
const dig = require('../../../shared/utils/dig');
const PanelAnimation = require('./panel-animation');
const PanelTheme = require('../../../shared/enums').Themes.Panel;

const PanelStatus = require('../../../shared/enums').PanelStatus;
const { PanelOffsetInPixels } = require('../../../shared/constants');

const PanelAnchor = require('./panel-anchor/panel-anchor');
const PanelHeader = require('./panel-header/panel-header');

const mapping = require('./templates').default;
const templateProvider = require('../shared/template-provider');

const tilesActionCreator = require('../../../client/actions/tiles-action-creator');
const customerAccountActionCreator = require('../../../client/actions/customer-account-action-creator');
const contentTileService = require('../../../client/services/content-tile-service');
const { pushClick } = require('shared/data-layer/push-click');

let getCtasAndEpisodes;
let getRelatedTiles;
let getProgrammeUuidFromTileData;
let isRegularContentTile;

class Panel extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            height: 'auto'
        };

        this.closePanel = this.closePanel.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.setAbsoluteHeight = this.setAbsoluteHeight.bind(this);
        this.orientationChangeListener = this.orientationChangeListener.bind(this);
        this.addRelatedTiles = this.addRelatedTiles.bind(this);
        this.addCtasAndEpisodes = this.addCtasAndEpisodes.bind(this);
        this.keepPanelInViewNoHero = this.keepPanelInViewNoHero.bind(this);
        this.keepPanelInView = this.keepPanelInView.bind(this);

        ({ contentTileService: {
            getCtasAndEpisodes, getRelatedTiles, getProgrammeUuidFromTileData, isRegularContentTile
        } = contentTileService } = this.props);
    }

    onClose() {
        const { routingInfo } = this.props;
        const { engine, area } = routingInfo;

        const paths = RouteHelper.getPanelClosePaths(routingInfo);
        const closeUrl = RouteHelper.getUrl(paths, routingInfo.params);
        const routeArea = engine.getArea(area);

        if (routeArea && routeArea.panelCloseUrl) {
            return this.props.router.push(routeArea.panelCloseUrl(closeUrl, routingInfo));
        }

        return this.props.router.push(closeUrl);
    }

    isAligned() {
        return Math.floor(this.node.getBoundingClientRect().top) <= PanelOffsetInPixels;
    }

    isPanelInViewPort() {
        // As there is now a new panel with a different size, this needs to take into consideration the panel's height rather than the viewport height
        const bottomEdge = this.node.getBoundingClientRect().bottom;
        const halfPanelHeight = Math.abs(this.node.getBoundingClientRect().height) / 2;
        const viewportHeight = window.innerHeight;

        // More than half the panel is visible when
        //   bottomEdge < viewportHeight + halfPanelHeight : The bottom of the panel is below the viewport but not at least half is still visible
        //   bottomEdge >= halfPanelHeight : The bottom of the panel doesn't go above the viewport
        return (bottomEdge < (viewportHeight + halfPanelHeight)) && (bottomEdge >= halfPanelHeight);
    }

    onKeyDown(event) {
        if (KeypressEvents.isEscapeKey(event)) {
            this.closePanel();
        }
    }

    orientationChangeListener() {
        // workaround for scroll issue after the orientation change when it's still being animated on iOS
        utils.repeatWithInterval(this.keepPanelInView, 3, 100);
        this.keepPanelInView();
    }

    keepPanelInView() {
        if (this.props.status === PanelStatus.OPEN) {
            const scrollBy = this.props.scrollTo ?
                ReactDOM.findDOMNode(this.props.scrollTo).getBoundingClientRect().top : this.node.getBoundingClientRect().top - PanelOffsetInPixels;

            window.scrollBy(0, Math.ceil(scrollBy));
        }
    }

    keepPanelInViewNoHero() {
        const scrollBy = this.node.getBoundingClientRect().top - PanelOffsetInPixels;
        window.scrollBy(0, Math.ceil(scrollBy));
    }

    removeShine() {
        this.node.classList.remove('fx');
    }

    closePanel() {
        pushClick({
            text: 'Close button',
            description: 'cta: Close panel button',
            url: window.location.href,
            location: 'Page'
        })

        this.node.classList.remove('fx');

        if (this.props.status === PanelStatus.OPEN) {
            this.props.panelActions.setStatus(PanelStatus.CLOSED);
        }
    }

    setAbsoluteHeight() {
        const panelInner = ReactDOM.findDOMNode(this.refs.panelInner);
        const childWithHeight = panelInner && panelInner.childNodes && panelInner.childNodes[1];
        const height = childWithHeight && childWithHeight.clientHeight && `${childWithHeight.clientHeight}px`;
        if (height !== this.state.height) {
            this.setState({ height });
            this.props.panelActions &&
                this.props.panelActions.resizeTriggered &&
                this.props.panelActions.resizeTriggered();
        }
    }

    addAllTileData() {
        const { tileActions, panelData: { uuid, ctas, episodes, related, channel } } = this.props;
        const isTileUpdatable = isRegularContentTile(this.props.panelData) && tileActions && tileActions.updateTile;
        const programmeUuid = getProgrammeUuidFromTileData({ uuid, episodes });
        const noCtasOrEpisodes = !ctas && !episodes;
        const regionalBouquetsExists = R.path(['customerAccountData', 'regionalBouquets'], this.props);
        if (uuid &&
            noCtasOrEpisodes &&
            isTileUpdatable &&
            regionalBouquetsExists) {
            const serviceId = channel && typeof channel.logoId === 'number' ? channel.logoId : undefined;
            return this.addCtasAndEpisodes({ uuid, serviceId })
                // eslint-disable-next-line no-shadow
                .then(({ episodes }) => getProgrammeUuidFromTileData({ uuid, episodes }))
                .then(programmeUUID => !related ? this.addRelatedTiles(programmeUUID) : Promise.reject())
                .catch(() => {
                    // TODO: Handle this exception appropriately
                    // The exception from addRelatedTiles.getRegionalInfo is handled
                    // in addRelatedTiles but the exception is thrown up here too
                });
        } else if (programmeUuid && !related && isTileUpdatable) {
            this.addRelatedTiles(programmeUuid);
        }
    }

    updateCorrectTile(changedFields) {
        const { tileActions: { updateTile }, panelData: { gallerySlug, slug: tileSlug, index: tileIndex } } = this.props;
        if (gallerySlug && tileSlug) {
            updateTile({ changedFields, gallerySlug, tileSlug });
        } else {
            updateTile({ changedFields, tileIndex });
        }
        return changedFields;
    }

    getRegionalInfo() {
        const regionalInfo = R.pathOr(null, ['customerAccountData', 'regionalBouquets'], this.props);
        if (regionalInfo && !regionalInfo.error) {
            return Promise.resolve(regionalInfo);
        }

        return Promise.reject();
    }

    addCtasAndEpisodes({ uuid, serviceId }) {
        return this.getRegionalInfo()
            .then(regionalInfo => getCtasAndEpisodes({ uuid, serviceId, regionalInfo }))
            .catch(() => getCtasAndEpisodes({ uuid, serviceId }))
            .then(({ ctas, episodes }) => this.updateCorrectTile({ ctas, episodes }));
    }

    addRelatedTiles(programmeUuid) {
        const { tileActions: { replaceTitlePageRelatedTiles }, panelData: { index: tileIndex }, routingInfo: { area } = {} } = this.props;
        return this.getRegionalInfo()
            .then(regionalInfo => getRelatedTiles({ uuid: programmeUuid, regionalInfo }))
            .catch(() => getRelatedTiles({ uuid: programmeUuid }))
            .then(changedFields => {
                const shouldReplaceTitlePageTiles = replaceTitlePageRelatedTiles && tileIndex === 0 && area === 'title';
                this.updateCorrectTile(changedFields);
                if (shouldReplaceTitlePageTiles) {
                    replaceTitlePageRelatedTiles({
                        relatedTiles: changedFields.related,
                        tileIndex
                    });
                }
            });
    }

    addActivationListeners() {
        WindowEvents.addHeroHiddenListener(this.keepPanelInViewNoHero);
    }

    removeActivationListeners() {
        WindowEvents.removeHeroHiddenListener(this.keepPanelInViewNoHero);
    }

    addPanelOpenedClass() {
        if (dig(this.props, 'routingInfo', 'location', 'action') === 'POP') {
            this.node.classList.add('panel-opened');
        }
    }

    componentDidMount() {
        this.addAllTileData();
        this.addActivationListeners();
        // The only applications that look to be calling Baggie still are Device Management and TV Pin and they don't appear to need the result of the service call
        // This is a temporary removal of the call to the Baggie service to see if anything actually breaks
        // Assuming not, this code along with the relevant reducers/actions & services will be removed

        // if (!R.path(['customerAccountData', 'regionalBouquets'], this.props) && R.path(['customerAccountActions', 'requestRegionalBouquets'], this.props)) {
        //     this.props.customerAccountActions.requestRegionalBouquets();
        // }
        this.node = ReactDOM.findDOMNode(this);

        this.animation = PanelAnimation.get(this.node, this.transitionFinishedListener.bind(this));

        WindowEvents.addOrientationChangeListener(this.orientationChangeListener);

        this.removeShine();

        if (this.props.status === PanelStatus.CLOSED) {
            this.props.panelActions.setStatus(PanelStatus.OPEN);
        } else if (this.props.status === PanelStatus.OPEN) {
            utils.postRender(this.setAbsoluteHeight);
            this.keepPanelInView();
        }

        this.addPanelOpenedClass();
    }

    componentWillUnmount() {
        WindowEvents.removeOrientationChangeListener(this.orientationChangeListener);
        this.removeActivationListeners();
    }

    transitionFinishedListener() {
        const { status, panelData: { isAppView } = {} } = this.props;

        if (status === PanelStatus.CLOSED) {
            this.onClose();
            this.node.classList.remove('panel-opened');
            return;
        }

        if (this.isAligned()) {
            const anchor = ReactDOM.findDOMNode(this.refs.anchor);
            if (anchor && anchor.firstChild) {
                anchor.firstChild.focus();
            }
        } else if (!isAppView) {
            this.node.querySelector('.panel-close').focus();
        }

        this.node.style.height = 'auto';
        this.node.classList.add('fx', 'panel-opened');
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevProps.panelData.slug !== this.props.panelData.slug) || !R.equals(prevProps.customerAccountData.regionalBouquets, this.props.customerAccountData.regionalBouquets)) {
            this.addAllTileData();
        }
        this.removeShine();

        if (!this.props.keepPanelOpen && this.props.panelData.slug !== prevProps.panelData.slug) {
            this.props.panelActions.setStatus(PanelStatus.OPEN);
        }

        if (this.props.status !== prevProps.status) {
            this.animation.updateStatus(this.props.status, this.props.scrollTo);
        }

        if (this.props.viewMode !== prevProps.viewMode) {
            this.keepPanelInView();
        }

        if ((this.props.resize || !R.equals(prevProps.routingInfo, this.props.routingInfo)) && this.state.height === prevState.height) {
            utils.postRender(this.setAbsoluteHeight);
        }
    }

    render() {
        const panel = this.props.panelData;
        const template = panel.panelTemplate;
        const theme = panel.panelTheme || PanelTheme.NAV;

        const provider = R.curry(templateProvider)(mapping);
        const Template = provider(template);
        const panelClassNames = classNames('panel', `${template}-template`, `${theme}-theme`, this.props.status, panel.panelClasses);

        return (
            <section className={panelClassNames} tabIndex="0" data-uuid={panel.uuid} onKeyDown={this.onKeyDown}>
                <div ref="anchor">
                    <PanelAnchor text={`${panel.title} overview`} />
                </div>
                <div className="o-container">
                    <div className="panel-inner" ref="panelInner">
                        { panel.isAppView || panel.pageTitle ? null : <PanelHeader onCloseBtnClick={this.closePanel} /> }
                        <Template
                            viewMode={this.props.viewMode}
                            payload={panel}
                            routingInfo={this.props.routingInfo}
                            userProfile={this.props.userProfile}
                            location={this.props.location}
                            scrollTo={this.props.scrollTo}
                            updateTile={R.propOr(null, 'updateTile')(this.props.tileActions)}
                            template={template}
                            provider={provider}
                            keepPanelInView={this.keepPanelInView}
                        />
                    </div>
                </div>
            </section>
        );
    }
}

Panel.defaultProps = {
    keepPanelOpen: false
};

function mapDispatchToProps(dispatch) {
    return {
        tileActions: bindActionCreators(tilesActionCreator, dispatch),
        customerAccountActions: customerAccountActionCreator(dispatch)
    };
}

function mapStateToProps({ experiments, customerAccountData }) {
    return {
        experiments,
        customerAccountData
    };
}

module.exports = connect(mapStateToProps, mapDispatchToProps)(withRouter(Panel));
