export const pushClick = ({ text, description, url, location, globalEvent = false }) => {
    if (typeof window !== 'undefined' && window.skyDataLayer) {
        window.skyDataLayer.push({
            event: 'click',
            globalEvent,
            element: {
                text,
                description,
                url,
                location
            },
            timestamp: Date.now()
        });
    }
};
