import React from 'react';
import classNames from 'classnames';

import mapping from './templates';
import templateProvider from '../shared/template-provider';
import tracking from 'client/tracking';
import { pushClick } from 'shared/data-layer/push-click';

class Tile extends React.Component {
    getClassNames() {
        const notSquareTemplates = [
            'coverimage',
            'covertext',
            'searchlinks',
            'iframe',
            'media',
            'split',
            'findoutmore',
            'rowcontent'
        ];
        const tile = this.props.tile;
        const channel = tile.brand ? `c-tile--${tile.brand.id}` : 'c-tile--default';
        const size = (tile.theme === 'text-with-background action-tile') ? 'c-tile--size-extrasmall' : `c-tile--size-${tile.size}`;
        const square = notSquareTemplates.indexOf(tile.tileTemplate) < 0 ? 'c-tile--square' : null;
        const shine = tile.url ? 'c-shine-context' : null;
        const template = `tile-template--${tile.tileTemplate}`;
        const theme = tile.theme ? `c-tile-theme--${tile.theme}` : null;
        const modifier = tile.modifier ? `c-tile--${tile.modifier}` : null;
        const panelTheme = tile.panelTheme ? `c-tile--${tile.panelTheme}` : 'c-tile--dark';
        const selected = this.props.selected ? 'is-selected' : null;
        const tileClasses = (tile.tileTemplate === 'searchlinks') ? 'c-search-links' : 'c-tile c-tile--fluid';
        const accountTilePIIClass = (tile.tileTemplate === 'account') ? 'PII' : '';

        const setChannelStyle = () => {
            const currentTile = this.props.tile;

            if (currentTile.tileTemplate === 'channel') {
                return `c-tile--channel-${currentTile.slug}`;
            }
        };

        const extraClasses = {
            'in-view': this.props.inView,
            'no-anim': this.props.noAnim
        };

        return classNames(tileClasses, size, square, shine, channel, template, theme, modifier, panelTheme, selected, extraClasses, setChannelStyle(), accountTilePIIClass);
    }

    getSize(isHomeWatchOrSearch) {
        const { tile } = this.props;

        const sizeMap = {
            extrasmall: classNames('o-layout__item', 'u-width-1/2', 'c-tile-fluid-scale-1/2', 'u-width-1/4@legacy-medium', 'c-tile-fluid-scale-1/4@legacy-medium'),
            small: classNames('o-layout__item', 'u-width-1/2@legacy-small', 'c-tile-fluid-scale-1/2@legacy-small', 'u-width-1/3@legacy-medium', 'c-tile-fluid-scale-1/3@legacy-medium'),
            mediumSmall: classNames('o-layout__item', 'u-width-1/2@legacy-small', 'c-tile-fluid-scale-1/2@legacy-small', 'u-width-1/4@legacy-medium', 'c-tile-fluid-scale-1/4@legacy-medium'),
            medium: classNames('o-layout__item', 'u-width-1/2@legacy-small', 'c-tile-fluid-scale-1/2@legacy-small', 'c-tile-fluid-scale-2/5@legacy-medium'),
            large: classNames('o-layout__item', 'u-width-1', 'c-tile-fluid-scale-1/1')
        };

        const extraClassesForTwoByTwoLayout = classNames('u-width-1/2', 'c-tile-fluid-scale-2/3');

        let sizeClass = null;

        if (tile.tileTemplate === 'searchlinks') {
            sizeClass = classNames('o-layout__item', 'u-width-3/4@legacy-medium', 'c-tile-fluid-scale-3/4@legacy-medium', 'o-search-links__wrapper');
        } else if (tile.theme === 'text-with-background action-tile') {
            sizeClass = classNames('o-layout__item', 'u-width-1/2@legacy-small', 'c-tile-fluid-scale-1/2@legacy-small', 'u-width-1/4@legacy-medium', 'c-tile-fluid-scale-1/4@legacy-medium', 'o-search-links__action');
        } else if (tile.theme === 'undefined top-search-tiles') {
            sizeClass = classNames('o-layout__item', 'u-width-1/3@legacy-small', 'c-tile-fluid-scale-1/3@legacy-small');
        } else {
            sizeClass = `${sizeMap[tile.size]} ${isHomeWatchOrSearch && (tile.size === 'small' || tile.size === 'mediumSmall' || tile.size === 'medium') ? extraClassesForTwoByTwoLayout : ''}`;
        }

        return sizeClass;
    }

    customTrackingHook(props, tileElement) {
        if (props.tile.adobeTarget) {
            tracking.linkEvent({
                element: tileElement,
                eventAction: 'linkClick',
                area: props.routingInfo.area,
                locationParams: props.routingInfo.params,
                adobeTarget: props.tile.adobeTarget
            });
        }
    }

    determineTileTrackingInformation() {
        const PagesEngine = require('shared/pages').default;
        const { routingInfo } = this.props;

        const area = PagesEngine.getArea(routingInfo.area);
        const infoBuilder = area && area.tracking &&
            typeof area.tracking.tileClickDetails === 'function' &&
            area.tracking.tileClickDetails;
        const trackingInfo = infoBuilder && infoBuilder(this.props.routingInfo, this.props.tile);
        if (trackingInfo) {
            return trackingInfo;
        }

        if (this.props.tile.gallerySlug) {
            return {
                label: `${this.props.index}:${this.props.tile.slug}`,
                context: this.props.tile.gallerySlug,
                pod: `slider-${this.props.tile.galleryIndex}`,
                tileListVar: [`${this.props.tile.galleryIndex}.${this.props.index}`, this.props.tile.brand ? this.props.tile.brand.id : this.props.tile.area, this.props.tile.gallerySlug, this.props.tile.slug].join(':')
            };
        }
        if (this.props.tile.collectionSlug) {
            return {
                context: this.props.tile.collectionSlug,
                theme: this.props.tile.brand ? this.props.tile.brand.id : null,
                label: `${this.props.index}:${this.props.tile.slug}`,
                pod: `tile-${this.props.tile.collectionIndex}`,
                tileListVar: [`${this.props.tile.collectionIndex}.${this.props.index}`, this.props.tile.brand ? this.props.tile.brand.id : this.props.tile.area, this.props.tile.collectionSlug, this.props.tile.slug].join(':')
            };
        }

        return {};
    }

    onTileClick(tileElement) {
        if (this.props.routingInfo.area === 'search') {
            return this.props.resultClicked(tileElement);
        }
        pushClick({
            text: tileElement.innerText,
            description: "cta: Tile",
            url: window.location.href,
            location: "Page"
        })

        return this.customTrackingHook(this.props, tileElement);
    }

    renderTileTemplate() {
        const { tile = {}, routingInfo, channelSponsor, viewMode, location, userProfile, showLogo } = this.props;
        const shouldShowLogo = showLogo !== undefined ? showLogo : tile.showLogo;
        const Template = templateProvider(mapping, tile.tileTemplate);

        return (
            <Template
                {...tile}
                routingInfo={routingInfo}
                showLogo={shouldShowLogo}
                channelSponsor={channelSponsor}
                viewMode={viewMode}
                location={location}
                userProfile={userProfile} />
        );
    }

    determineTheme() {
        if (this.props.tile.brand) {
            return this.props.tile.brand.id;
        }
        if (this.props.routingInfo && this.props.routingInfo.params) {
            return this.props.routingInfo.params.channel;
        }
        return null;
    }

    determineTileLayout() {
        const { area } = this.props.routingInfo;
        return area === 'home' || area === 'watch' || area === 'search';
    }

    render() {
        if (!this.props.tile.tileTemplate) {
            console.log('missing tile template for tile', this.props.tile); // eslint-disable-line no-console
        }
        const defaultTileListVar = [this.props.index, this.props.tile.brand ? this.props.tile.brand.id : this.props.tile.area, this.props.tile.slug].join(':');
        const defaultTheme = this.determineTheme();
        const tileListArea = this.props.routingInfo ? this.props.routingInfo.area : 'home';
        const trackingEnabled = this.props.routingInfo.area !== 'search';
        const trackingInfo = this.determineTileTrackingInformation();

        return (
            <div className={this.getSize(this.determineTileLayout())}>
                <section className={this.getClassNames()}
                    data-tracking={trackingEnabled}
                    data-tracking-label={trackingInfo.label || this.props.tile.slug}
                    data-tracking-description={`cta:${trackingInfo.label || this.props.tile.slug}`}
                    data-tracking-location="Page"
                    data-slug={this.props.tile.slug}
                    data-tracking-module={trackingInfo.module || 'page'}
                    data-tracking-pod={trackingInfo.pod || `tile-${this.props.index}-${this.props.tile.slug}`}
                    data-tracking-context={trackingInfo.context || this.props.tile.area}
                    data-tracking-theme={trackingInfo.theme || defaultTheme}
                    data-tracking-event="tileClicked"
                    data-tracking-variable="tileList"
                    data-tracking-value={trackingInfo.tileListVar || defaultTileListVar}
                    data-content={tileListArea}
                    data-tracking-other={trackingInfo.other || ''}
                    ref={tileElement => this._tileElement = tileElement}
                    onClick={() => this.onTileClick(this._tileElement)}>
                    {this.renderTileTemplate()}
                </section>
            </div>
        );
    }
}

export default Tile;
